.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
}

.nav > .nav-link:active {
  color: #ffffff;
  background-color: #6418c3;
  border-color: #dee2e6 #dee2e6 #f9f9f9;
  border: 1px solid;
  border-radius: 12px;
}

.nav-tabs .nav-item {
  min-width: 130px;
  text-align: center;
}
