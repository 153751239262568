/* .MuiTablePaginationUnstyled-actions > button {
  padding-left: 10px;
  background: #673ab7;
  border-radius: 9px;
  color: white;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  font-size: 1.18rem;
}
 */

.MuiTablePagination-spacer.css-1psng7p-MuiTablePagination-spacer {
  flex: 0 1 0%;
}

/* .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex;
  justify-content: end;
  align-items: baseline;
  margin-top: 26px;
  border-top: 1px solid grey;
  padding-top: 15px;
  padding-bottom: 15px;
}
 */

.css-1wif0xq {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: baseline;
  padding-left: 16px;
  min-height: 52px;
  padding-right: 2px;
  flex-wrap: wrap;
  justify-content: end;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #80808061;
  margin-top: 12px;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: baseline;
  padding-left: 16px;
  min-height: 52px;
  padding-right: 2px;
  flex-wrap: wrap;
  justify-content: end;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #80808061;
  margin-top: 12px;
}

MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-1wif0xq {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: baseline;
  padding-left: 16px;
  min-height: 52px;
  padding-right: 2px;
  flex-wrap: wrap;
  justify-content: end;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #80808061;
  margin-top: 12px;
}
