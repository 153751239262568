.MuiTablePagination-spacer.css-1psng7p-MuiTablePagination-spacer {
  flex: 0 1 0%;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex;
  justify-content: end;
  align-items: baseline;
  margin-top: 26px;
  border-top: 1px solid grey;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-wrap: wrap;
}

/* div.container-fluid {
  background-image: linear-gradient(
    64.5deg,
    rgba(245, 116, 185, 1) 14.7%,
    rgba(89, 97, 223, 1) 88.7%
  );
}
 */
